// muesumverse images
import museumLogo from "../../../assets/images/muesum/Museum-Logo.png";
import museumImg from "../../../assets/images/muesum/screen.png";
import museumImg1 from "../../../assets/images/muesum/screen-1.png";
import museumImg2 from "../../../assets/images/muesum/screen-2.png";
import museumImg3 from "../../../assets/images/muesum/screen-3.png";
import museumImg4 from "../../../assets/images/muesum/screen-4.png";

//everyDay meat images

import meatLogo from "../../../assets/images/everyday-Meat/everydat_meat-logo.png";
import meatImg1 from "../../../assets/images/everyday-Meat/screen-1.png";
import meatImg2 from "../../../assets/images/everyday-Meat/screen-2.png";
import meatImg3 from "../../../assets/images/everyday-Meat/screen-3.png";
import meatImg5 from "../../../assets/images/everyday-Meat/screen-5.png";
import meatImg6 from "../../../assets/images/everyday-Meat/screen-6.png";

//Decathlon images
import decathlonLogo from "../../../assets/images/decathlon/decathlon-icon.png";
import decathlonimg1 from "../../../assets/images/decathlon/image-1.png";
import decathlonimg2 from "../../../assets/images/decathlon/image-2.png";
import decathlonimg3 from "../../../assets/images/decathlon/image-3.png";
import decathlonimg4 from "../../../assets/images/decathlon/image-4.png";
import decathlonimg5 from "../../../assets/images/decathlon/image-5.png";
import decathlonimg6 from "../../../assets/images/decathlon/image-6.png";

// order-lift Images
import orderLogo from "../../../assets/images/order-lift/orderRif-logo.png";
import orderImg5 from "../../../assets/images/order-lift/image-1.png";
import orderImg2 from "../../../assets/images/order-lift/image-3.png";
import orderImg3 from "../../../assets/images/order-lift/image-4.png";
import orderImg4 from "../../../assets/images/order-lift/image-5.png";
import orderImg1 from "../../../assets/images/order-lift/image-6.png";

import orderRift1 from "../../../assets/images/order-lift/orderRift1.png";
import orderRift2 from "../../../assets/images/order-lift/orderRift2.png";
import orderRift3 from "../../../assets/images/order-lift/orderRift3.png";
import orderRift4 from "../../../assets/images/order-lift/orderRift4.png";
import orderRift5 from "../../../assets/images/order-lift/orderRift5.png";
import orderRift6 from "../../../assets/images/order-lift/orderRift6.png";

//Iblood images
import ibloodLogo from "../../../assets/images/iblood/iblood-plasma-icon.png";
import ibloodImg1 from "../../../assets/images/iblood/image-1.png";
import ibloodImg2 from "../../../assets/images/iblood/image-2.png";
import ibloodImg3 from "../../../assets/images/iblood/image-3.png";
import ibloodImg4 from "../../../assets/images/iblood/image-4.png";
import ibloodImg5 from "../../../assets/images/iblood/image-5.png";

//Docle images
import docleImg1 from "../../../assets/images/docleMusic/image-1.png";
import docleImg2 from "../../../assets/images/docleMusic/image-2.png";
import docleImg3 from "../../../assets/images/docleMusic/image-3.png";

//geneo images

import geneoLogo from "../../../assets/images/geneo/geneo-icon.png";
import geneoImg1 from "../../../assets/images/geneo/image-1.webp";
import geneoImg2 from "../../../assets/images/geneo/image-2.webp";
import geneoImg3 from "../../../assets/images/geneo/image-3.webp";
import geneoImg4 from "../../../assets/images/geneo/image-4.webp";
import geneoImg5 from "../../../assets/images/geneo/image-5.webp";

//Crapbin Images
import crapbinLogo from "../../../assets/images/crapbin/crapbin-logo.png";
import crapbinImg1 from "../../../assets/images/crapbin/mobile-1.png";
import crapbinImg2 from "../../../assets/images/crapbin/mobile-2.png";
import crapbinImg3 from "../../../assets/images/crapbin/mobile-3.png";
import crapbinImg4 from "../../../assets/images/crapbin/mobile-4.png";

//billionaires images
import biLogo from "../../../assets/images/billionaires/billinarios-logo1.png";
import biImg1 from "../../../assets/images/billionaires/image-1.png";
import biImg2 from "../../../assets/images/billionaires/image-2.png";
import biImg3 from "../../../assets/images/billionaires/image-3.png";
import biImg4 from "../../../assets/images/billionaires/image-4.png";
import biImg5 from "../../../assets/images/billionaires/image-5.png";

//Tulip AI Based images
import tulipLogo from "../../../assets/images/Tulip-AI-Based/tulip-icon.png";
import tulipImgHome from "../../../assets/images/Tulip-AI-Based/image.png";
import tulipImg1 from "../../../assets/images/Tulip-AI-Based/image-1.png";
import tulipImg2 from "../../../assets/images/Tulip-AI-Based/image-2.png";
import tulipImg3 from "../../../assets/images/Tulip-AI-Based/image-3.png";
import tulipImg4 from "../../../assets/images/Tulip-AI-Based/image-4.png";
import tulipImg5 from "../../../assets/images/Tulip-AI-Based/image-5.png";
import tulipImg6 from "../../../assets/images/Tulip-AI-Based/image-6.png";

// Amal Alias images
import amalAlias_logo from "../../../assets/images/amal-alias/dr-amal-alias-logo.png";
import amalImg1 from "../../../assets/images/amal-alias/image-1.jpeg";
import amalImg2 from "../../../assets/images/amal-alias/image-2.jpeg";

import remat_logo from "../../../assets/images/remat/remat_logo.svg";
import remat1 from "../../../assets/images/remat/remat-img1.png";
import remat2 from "../../../assets/images/remat/remat-img2.png";
import remat3 from "../../../assets/images/remat/remat-img3.png";
import remat4 from "../../../assets/images/remat/remat-img4.png";

import pepsiLogo from "../../../assets/images/pepsi/pepsilogo.svg";
import pepsiImg from "../../../assets/images/pepsi/pepsiMobile.png";
import pepsi1 from "../../../assets/images/pepsi/pepsi1.png";
import pepsi2 from "../../../assets/images/pepsi/pepsi2.jpeg";
import pepsi3 from "../../../assets/images/pepsi/pepsi3.jpeg";
import pepsi4 from "../../../assets/images/pepsi/pepsi4.jpeg";

import samiLogo from "../../../assets/images/4s_project/sami.svg";
import aramcoLogo from "../../../assets/images/4s_project/aramco.svg";
import armcoImg from "../../../assets/images/4s_project/armcoMobileView.png";
import four_sImage1 from "../../../assets/images/4s_project/4simage2.jpeg";
import four_sImage2 from "../../../assets/images/4s_project/4simage3.png";
import four_sImage3 from "../../../assets/images/4s_project/4simage4.jpeg";

export const products1 = [
  {
    id: "Smart_parking",
    name: "Smart parking",
    title: "Riyadh smart parking",
    desc: `Discover Riyadh Smart Parking App: locate & pay for spots via your phone! Effortless parking in the city—navigate, reserve, and pay hassle-free!`,
    logo: remat_logo,
    mobile: remat1,
    tech: ["Next Js", "Node Js", "Framer motion"],
    subImages: [remat2, remat3, remat4, remat1],
    aboutAppDesc:
      "With an entrance, exit and empty slot guide, customers can book their parking spaces online as well, whether it is for street parking, open parking or building parking.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc:
      "At Riyadh Parking, we're a passionate team dedicated to transforming urban parking experiences. Our app simplifies city parking, offering convenience at your fingertips. With a focus on innovation and user-centric solutions, we strive to make navigating city streets a breeze. Join us in redefining how you park!",
    featureSubDesc: [
      { title: "FULL FREE CHAT", desc: "FULL FREE CHAT" },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "24/7 SUPPORT BY REAL PEPOLE",
      },
      { title: "UNLIMITER FEATURES", desc: "UNLIMITER FEATURES" },
      { title: "AWSOME UI DESIGN", desc: "AWSOME UI DESIGN" },
      { title: "ISO & ANDROIND VERSION", desc: "ISO & ANDROIND VERSION" },
      { title: "RETINA READY GREAPHICS", desc: "RETINA READY GREAPHICS" },
    ],
    appLookDesc: "add content",
    downloads1: "4K+",
    likes: "0.8K+",
    rating: "0.4K+",
    // link: "https://museumverse.net/",
  },

  {
    id: "4s_project",
    name: "Smart Site Safety Solutions ",
    title: "Smart Site Safety Solutions",
    desc: `Desc to be add`,
    logo: aramcoLogo,
    mobile:armcoImg,
    tech: ["React Js", "Node Js"],
    subImages: [armcoImg],
    aboutAppDesc: "App description to be add",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "feature Desc to add",
    featureSubDesc: [
      { title: "FULL FREE CHAT", desc: "FULL FREE CHAT" },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "24/7 SUPPORT BY REAL PEPOLE",
      },
      { title: "UNLIMITER FEATURES", desc: "UNLIMITER FEATURES" },
      { title: "AWSOME UI DESIGN", desc: "AWSOME UI DESIGN" },
      { title: "ISO & ANDROIND VERSION", desc: "ISO & ANDROIND VERSION" },
      { title: "RETINA READY GREAPHICS", desc: "RETINA READY GREAPHICS" },
    ],
    appLookDesc: "add content",
    downloads1: "4K+",
    likes: "0.8K+",
    rating: "0.4K+",
    // link: "https://museumverse.net/",
  },

  {
    id: "Pepsico",
    name: "Pepsi",
    title: "Pepsi productivity  and reduce cost with automation services",
    desc: `Desc`,
    logo: pepsiLogo,
    mobile: pepsiImg,
    tech: ["React Js", "Node Js"],
    subImages: [pepsiImg,],
    aboutAppDesc: "App description to be add",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "feature Desc to add",
    featureSubDesc: [
      { title: "FULL FREE CHAT", desc: "FULL FREE CHAT" },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "24/7 SUPPORT BY REAL PEPOLE",
      },
      { title: "UNLIMITER FEATURES", desc: "UNLIMITER FEATURES" },
      { title: "AWSOME UI DESIGN", desc: "AWSOME UI DESIGN" },
      { title: "ISO & ANDROIND VERSION", desc: "ISO & ANDROIND VERSION" },
      { title: "RETINA READY GREAPHICS", desc: "RETINA READY GREAPHICS" },
    ],
    appLookDesc: "add content",
    downloads1: "4K+",
    likes: "0.8K+",
    rating: "0.4K+",
    // link: "https://museumverse.net/",
  },

  {
    id: "Order_Rift",
    name: "Order Rift",
    // title: "users can buy apparels, from nearby shops online and get it delivered to home.",
    title: "Order Rift Mobile App",
    desc: " Order Rift app is an online marketplace which allows users to buy products from nearby retail store. It also comes with 'OrderRift ERP', a shop automation system which enables small and large scale retailers to automate and digitise their outlet, while creating a seemless sales channel at OrderRift Marketplace.",
    logo: orderLogo,
    mobile: orderRift1,
    tech: ["React Native", "Java"],
    subImages: [
      orderRift2,
      orderRift3,
      orderRift4,
      orderRift1,
      orderRift5,
      orderRift6,
    ],
    aboutAppDesc:
      "Using Order Rift users can buy apparels,clothing,shoes,Furniture, and all essentials from nearby shops online and get it delivered to home.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "0.1K+",
    likes: "20",
    rating: "8",
    // link: "https://play.google.com/store/apps/details?id=com.order_rift.user_app&hl=en_SG",
  },

  {
    id: "Museumverse",
    name: "Museumverse",
    title: "World of heritage and museums in stunning 3D",
    desc: ` Step into a world where the past comes alive in stunning 3D. Welcome to Museumverse,
      the innovative platform revolutionizing the way we experience museums, heritage, and artifacts. `,
    logo: museumLogo,
    mobile: museumImg,
    tech: ["React Js", "Node Js"],
    subImages: [museumImg1, museumImg2, museumImg3, museumImg4, museumImg],
    aboutAppDesc:
      "Step into a world where the past comes alive in stunning 3D. Welcome to Museumverse, the innovative platform revolutionizing the way we experience museums, heritage, and artifacts.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc:
      "Museumverse is where technology meets heritage, where the virtual meets the tangible. Together, let us bridge the gap between the past and the present, opening doors to a world of knowledge and cultural appreciation. Embark on your journey through Museumverse, and discover the wonders that await.",
    featureSubDesc: [
      { title: "FULL FREE CHAT", desc: "FULL FREE CHAT" },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "24/7 SUPPORT BY REAL PEPOLE",
      },
      { title: "UNLIMITER FEATURES", desc: "UNLIMITER FEATURES" },
      { title: "AWSOME UI DESIGN", desc: "AWSOME UI DESIGN" },
      { title: "ISO & ANDROIND VERSION", desc: "ISO & ANDROIND VERSION" },
      { title: "RETINA READY GREAPHICS", desc: "RETINA READY GREAPHICS" },
    ],
    appLookDesc:
      "Museumverse is not just about preserving the past; it's about shaping the future. By archiving heritage objects and creating a centralized database, we ensure the preservation of invaluable information for generations to come. We also recognize the importance of monetization opportunities for museums, and through our platform, we unlock new avenues for revenue generation through the online shop for museum souvenirs and collectibles",
    downloads1: "4K+",
    likes: "0.8K+",
    rating: "0.4K+",
    link: "https://museumverse.net/",
  },

  //everaymeat insta id=2
  {
    id: "Every_Day_Meat",
    name: "Every Day Meat",
    // title: "Every Day Meat App This application allows users to buy meat online",
    title: "Every Day Meat App",
    desc: " This application allows users to buy meat online . This Enterprise solution consists of delivery app, desktop software and a consumer app harnessing the power of GPS system and developing ingenious Routing Solutions.",
    logo: meatLogo,
    mobile: meatImg1,
    tech: ["Android", "IOS", "Node Js"],
    subImages: [meatImg2, meatImg3, meatImg5, meatImg6, meatImg1],
    aboutAppDesc:
      "InstaMeat App, you are just a few simple steps away from getting the choice of your fresh, halal, and quality meat delivered to your doorstep anywhere in Hyderabad. Whether you are throwing a party at your home or celebrating a festival with all your loved ones, we promise fast delivery and fresh meat so that your guests eat heart-content meal. You can easily choose from the categories with assorted collection of online fresh meat products in Hyderabad",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "100K+",
    likes: "4.5K+",
    rating: "1.25K+",
    link: "https://play.google.com/store/apps/details?id=com.everydaymeat.mobile",
  },
  //Decathlon Play id=3
  {
    id: "Decathlon_Play",
    name: "Decathlon Play",
    title: "Get up and get playing with regular sports events,",
    desc: "Decathlon Play is a promise by Decathlon dedicated to making more people, more active, more often. Experience the convenience and support of playing sports on your terms - whenever and wherever you want across India.",
    logo: decathlonLogo,
    mobile: decathlonimg1,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [
      decathlonimg2,
      decathlonimg3,
      decathlonimg4,
      decathlonimg5,
      decathlonimg6,
      decathlonimg1,
    ],
    aboutAppDesc: "Get up and get playing with regular sports events",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc:
      "Stands for accessibility for the many and not the few. We want to create an ecosystem that encourages a culture of sports in our community. Easy interactions and support from partners, coaches and players + advice guides to help you as you go.",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "100K+",
    likes: "54K+",
    rating: "5.2K+",
    link: "https://play.decathlon.in/",
  },
  //iBlood Plasma id=5
  {
    id: "iBlood_Plasma",
    name: "iBlood Plasma",
    title: "iBlood Plasma - Find Blood Pl",
    desc: "iBlood Plasma is an initiative to save lives. This app is to bridge the time and distance that play major role in finding blood donors to patients in dire emergencies. App user can search for donors that are closest to the place of need and contact them. Donors nearby can immediately come forth to donate. Users can also be donors and contribute to humanity by saving lives.  ",
    logo: ibloodLogo,
    mobile: ibloodImg1,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [ibloodImg2, ibloodImg3, ibloodImg4, ibloodImg5, ibloodImg1],
    aboutAppDesc:
      "It is very disheartening to see the no of people getting affected by Covid in India. Finding a plasma donor quickly is becoming a huge problem now for all blood groups for covid treatment.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc:
      "iBlood Plasma is a location based app to search for nearby blood Plasma donors and establishes communication between both donor and seeker. The only rule is whoever is seeking donation must pledge to donate when somebody else is in need.",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "67K+",
    likes: "8.1K+",
    rating: "3K+",
    link: "https://play.google.com/store/apps/details?id=com.ncpl.iBlood&hl=ar&gl=US",
  },

  //Geneo id=6
  {
    id: "Geneo",
    name: "Geneo",
    title: "Geneo works as an online learning app for classes 5 to 10",
    desc: "Geneo works as an online learning app for classes 5 to 10 offering curated study content for Science, Maths, English and Social Science. The primary aim of this learning solution is to make school curriculum easier to grasp for students and help them create",
    logo: geneoLogo,
    mobile: geneoImg1,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [geneoImg2, geneoImg3, geneoImg4, geneoImg5, geneoImg1],
    aboutAppDesc:
      "Geneo is an interactive and affordable new age blended learning platform. Schoolnet India's flagship Personalized Learning Solution, it has been designed to make learning simple, smart, and effective.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "100K+",
    likes: "8K+",
    rating: "0.6K+",
    link: "https://play.google.com/store/apps/details?src=AppAgg.com&id=com.org.geneo&referrer=utm_source%3DAppAgg.com%26utm_medium%3DAppAgg%26utm_campaign%3DAppAgg",
  },

  //Tulip AI Based Health diagnostics id=7
  {
    id: "Tulip",
    name: "Tulip",
    title: "Tulip AI Based Health diagnostics",
    desc: "Answering our brief survey where you can share your unique symptoms and lifestyle. Our AI system will utilize this valuable health-related data to precisely match you with the ideal selection of recommendations, tailored to cater your individual needs.",
    logo: tulipLogo,
    mobile: tulipImgHome,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [
      tulipImg1,
      tulipImg2,
      tulipImg3,
      tulipImg4,
      tulipImg5,
      tulipImg6,
    ],
    aboutAppDesc:
      "AI Based Health diagnostics is your personalized compass in the vast world of natural health. We harness the power of advanced AI technology and the timeless wisdom of nature to curate science-backed, personalized health recommendations that speak directly to your unique needs and goals.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "",
    likes: "8K+",
    rating: "0.3K",
    link: "https://web-prod.meettulip.com/smart-recommendations/chat?symptom-condition=headache%20light",
  },

  //CrapBin App id=9
  {
    id: "CrapBin_App",
    name: "CrapBin App",
    // title: "Crapbin provides doorstep service to sell your scrap/dry waste and get rid off of junk items and trash in Hyderabad.",
    title: "Crapbin provides doorstep service to sell your scrap .",
    desc: ` Crapbin is a modern-day online Kabadiwala helps you to get rid of your scrap waste in the most convenient way possible.  On the selected date our executive will arrive at the location and collects the stuff you want to clear, in return, you will get paid a standard amount based on the weight of the items. `,
    logo: crapbinLogo,
    mobile: crapbinImg1,
    tech: ["Android", "IOS", "Flutter", "Laravel"],
    subImages: [crapbinImg2, crapbinImg3, crapbinImg4, crapbinImg1],
    aboutAppDesc:
      "Crapbin provides doorstep service to sell your scrap/dry waste and get rid off of junk items and trash in Hyderabad.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "1K+",
    likes: "300",
    rating: "50",
    link: "https://crapbin.com/",
  },

  //The Billionaires League id=10
  {
    id: "Billionaires_League",
    name: "The Billionaires League",
    title: "The Billionaires League team our mobile app.",
    // title: "The Billionaires League team on campus educating students about stock market investing at an early age using our mobile app.",
    desc: " The Billionaires league mobile app ,which is available on ios and android stores , gives user's the ability to purchase stocks from New york Stock exchange from any part of the globe. This app also provides virtual money to see your portfolio grow in real time. This app has an USA patent. ",
    logo: biLogo,
    mobile: biImg5,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [biImg1, biImg2, biImg3, biImg4, biImg5],
    aboutAppDesc:
      "Awarded Best Portfolio Management Application 2017 by Capital Finance International, the Billionaires' League allow users to mirror the world's wealthiest billionaire investors, investment banks and pension funds stock portfolio in real time, with a click of a button.",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "2.2K+",
    likes: "378",
    rating: "56",
    link: "https://appadvice.com/app/the-billionaires-league/1189492178",
  },

  //Amal alias id=11
  {
    id: "Dr_Amal_Alias",
    name: "Dr Amal Alias",
    title:
      "Book appointments with Dr Amal Alias Fertility And Gynaecology Centre.",
    desc: "This app allows users to Book appointments with Dr Amal Alias Fertility And Gynaecology Centre and manage their own appointments,promotions,subscriptions and referrals.",
    logo: amalAlias_logo,
    mobile: amalImg1,
    tech: ["Android", "IOS", "Flutter", "PHP", "Laravel"],
    subImages: [amalImg2, amalImg1],
    aboutAppDesc: "",
    aboutAppSubDesc: [
      {
        title: "CREATIVE DESIGN",
        description:
          "Maintain a consistent design throughout your app. This includes using a cohesive color scheme, typography, and design elements. Consistency helps build a recognizable and trustworthy brand.",
      },
      {
        title: "EASY TO USE",
        description:
          "Introduce users to your app gradually. Avoid overwhelming them with too many features at once. Start with core functionalities and allow users to explore more advanced features over time.",
      },
      {
        title: "BEST USER EXPERINCE",
        description:
          "Put your users at the center of the design process. Understand their needs, behaviors, and preferences through research, surveys, and usability testing.",
      },
    ],
    featureDesc: "",
    featureSubDesc: [
      {
        title: "FULL FREE CHAT",
        desc: "Explain how using your app will make the user's life easier, more enjoyable, or more efficient.",
      },
      {
        title: "24/7 SUPPORT BY REAL PEPOLE",
        desc: "If your app receives updates or new features over time",
      },
      {
        title: "UNLIMITER FEATURES",
        desc: "If your content is displayed on a website or any other platform, ensure that it's optimized for mobile devices.",
      },
      {
        title: "AWSOME UI DESIGN",
        desc: "Visuals can provide a quick and easy way for users to understand your app's features.",
      },
      {
        title: "ISO & ANDROIND VERSION",
        desc: "If your app receives updates or new features over time, communicate these updates to your users regularly. This helps to keep them engaged and informed.",
      },
      {
        title: "RETINA READY GREAPHICS",
        desc: "Write in a way that resonates with your target audience. Use language and tone that aligns with your brand and speaks directly to the needs and interests of your users.",
      },
    ],
    appLookDesc: "",
    downloads1: "4.5K+",
    likes: "214",
    rating: "23",
    link: "",
  },

  //Dolce Music id=12
  // {
  //   id: 12,
  //   name: "Dolce Music",
  //   // title: "This application helps students to check their schedule ,view their complete profile",
  //   title: "Dolce Music App",
  //   desc: `This in an application for dolce music academy,which helps the tutor to manage his activities in institute and the student to keep track of their progress and fee. `,
  //   logo: "../images/products/docle_img-5.png",
  //   mobile: "../images/products/docle-music-mobile.png",
  //   tech: ["Android", "IOS", "Flutter", "Ruby Rails",],
  //   subImages: [docleImg1, docleImg2, docleImg3],
  //   aboutAppDesc: "This application helps students to check their schedule ,view their complete profile and always stay up to date with the institute's activities.It also helps them manage their payment structure and enquire about the timings and various courses being offered.",
  //   aboutAppSubDesc: [
  //     { title: "CREATIVE DESIGN", description: "description1" },
  //     { title: "EASY TO USE", description: "description1" },
  //     { title: "BEST USER EXPERINCE", description: "description1" },
  //   ],
  //   featureDesc: "",
  //   featureSubDesc: [
  //     { title: "FULL FREE CHAT", desc: "" },
  //     { title: "24/7 SUPPORT BY REAL PEPOLE", desc: "" },
  //     { title: "UNLIMITER FEATURES", desc: "" },
  //     { title: "AWSOME UI DESIGN", desc: "" },
  //     { title: "ISO & ANDROIND VERSION", desc: "" },
  //     { title: "RETINA READY GREAPHICS", desc: "" },
  //   ],
  //   appLookDesc: "",
  //   downloads1: 45095,
  //   likes: 23031,
  //   rating: 8711,
  // },
  //The Education App id=13
  // {
  //   id: 13,
  //   name: "The Education App",
  //   title: "All-In-One App for Teachers, Students & Parents",
  //   title1: "All-In-One App for Teachers, Students & Parents. ",
  //   desc: " This is a School applications which offers features like event notifications, fee payment, class wise student statistics, e-learning. This app is available in android and ios platforms.",
  //   logo: "../../images/clients/lovedale-school-logo.png",
  //   mobile: "../images/products/education-mobile.png",
  //   tech: ["Android", "IOS", "PHP", "Laravel"],
  //   subImages: [],
  //   aboutAppDesc: "",
  //   aboutAppSubDesc: [
  //     { title: "CREATIVE DESIGN", description: "description1" },
  //     { title: "EASY TO USE", description: "description1" },
  //     { title: "BEST USER EXPERINCE", description: "description1" },
  //   ],
  //   featureDesc: "",
  //   featureSubDesc: [
  //     { title: "FULL FREE CHAT", desc: "" },
  //     { title: "24/7 SUPPORT BY REAL PEPOLE", desc: "" },
  //     { title: "UNLIMITER FEATURES", desc: "" },
  //     { title: "AWSOME UI DESIGN", desc: "" },
  //     { title: "ISO & ANDROIND VERSION", desc: "" },
  //     { title: "RETINA READY GREAPHICS", desc: "" },
  //   ],
  //   appLookDesc: "",
  //   downloads1: 1,
  //   likes: 1,
  //   rating: 1,
  // },
];

// not ussing any where
export const cardData = [
  {
    id: 1,
    title: "Support Multiple Enterprises",
    icon: "https://wholphintech.com/images/feature_sec_images/multiple_shops_img_1.png",
    desc: "Our software has the capability to run multiple enterprises,which create an centralised hold over the branches.",
  },
  {
    id: 2,
    title: "Customization",
    icon: "https://wholphintech.com/images/feature_sec_images/customization_img_1.png",
    desc: "we meet all our client requirements trough adding or changing the freatures from time to time fullfilling the need of the enterprise.",
  },
  {
    id: 3,
    title: "User friendly and Collaborative",
    icon: "https://wholphintech.com/images/feature_sec_images/webandmobile_img_1.png",
    desc: "Our software also allows log in for regular clients who can only access certain sections of the accounting records, this increases the collaboration with the customer.",
  },
  {
    id: 4,
    title: "Customer and Supplier Management",
    icon: "https://wholphintech.com/images/feature_sec_images/customer_and_supplier_management_img_1.png",
    desc: "All our regular client details are stored in and accessed from one place making dealing with customers and suppliers convenient, you can filter search to quickly find out clients historical transactions and balances.",
  },
];
