import { Box, Grid, IconButton, Stack } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useState } from "react";


import webImg from "../../assets/icons/website-codes.png";
import androidImg from "../../assets/icons/android.png";
import appleImg from "../../assets/icons/apple.png";
import pwaImg from "../../assets/icons/setting.png";
import desktopImg from "../../assets/icons/monitor-screen.png";
import appImg from "../../assets/icons/app-development.png";

import musemCompanyLogo from "../../assets/images/muesum/mussuemverse_logo.jpeg";
import IndiaLogo from "../../assets/images/muesum/india_logo1.jpg";
import musem from "../../assets/images/muesum/musem.jpeg";

import pepsi from "../../assets/images/pepsi/pepsi.jpeg";
import pepsiLogo from "../../assets/images/pepsi/pepsilogo.svg";
import usaFlag from "../../assets/images/pepsi/usa-flag.svg";

import remarth from "../../assets/images/remat/remarth.jpeg";
import samiLogo from "../../assets/images/remat/sami.svg";

import abqueqHomePage from "../../assets/images/4s_project/4simage4.jpeg";
import aramcoLogo from "../../assets/images/4s_project/aramco.svg";
import saudiFlag from "../../assets/images/4s_project/saudi-flag.svg";

import { motion } from "framer-motion";
import Styles from "./AppC.module.css";
import { Link } from "react-router-dom";

const heroContent = [
  {
    id: 1,
    content:
      "Discover Riyadh smart parking app: locate and pay for spots via your phone",
    img: remarth,
    logo: samiLogo,
    flag: saudiFlag,
  },
  {
    id: 2,
    content:
      "Imporve your productivity and reduce cost with automation services",
    img: pepsi,
    logo: pepsiLogo,
    flag: usaFlag,
  },
  {
    id: 3,
    content: "Smart Site Safety Solutions",
    // content:"Construction smart site safety solution (4S™) technology to monitor and track contractor workers during construction to prevent or minimize site incidents",
    // content:"Technology to monitor and track  workers during construction to minimize site incidents",
    img: abqueqHomePage,
    logo: aramcoLogo,
    flag: saudiFlag,
  },
  {
    id: 4,
    content: "Discover global collection of stunning artifacts in stunning 3d",
    img: musem,
    logo: musemCompanyLogo,
    flag: IndiaLogo,
  },
];

const services = [
  {
    id: 1,
    name: "Android app Development",
    img: androidImg,
  },
  {
    id: 2,
    name: "Ios app Development",
    img: appleImg,
  },
  {
    id: 3,
    name: "Web app Development",
    img: webImg,
  },
  {
    id: 4,
    name: "Desktop App Development",
    img: desktopImg,
  },
  {
    id: 5,
    name: "Fullstack Web & Mobile",
    img: appImg,
  },
  {
    id: 6,
    name: "PWS App Development",
    img: pwaImg,
  },
];

export default function HeroSectionPage() {
  const [heroIndex, setHeroIndex] = useState(0);

  const handleClick = (value) => {
    if (value === "inc" && heroIndex + 1 <= heroContent.length - 1) {
      setHeroIndex((state) => (state = state + 1));
      return;
    }

    if (value === "inc" && heroIndex === heroContent.length - 1) {
      setHeroIndex((state) => state - (heroContent.length - 1));
      return;
    }

    if (value === "dec" && heroIndex + 1 > 1) {
      setHeroIndex((state) => state - 1);

      return;
    }
    if (heroIndex === 0) {
      setHeroIndex(heroContent.length - 1);

      return;
    }
  };

  return (
    <>
      <div className={Styles.heroContainer}>
        <div className={Styles.hero}>
          <div
            className={Styles.imgBox}
            style={{ backgroundImage: `url(${heroContent[heroIndex].img})` }}
          ></div>

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 6, md: 0 }}
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            maxWidth="1000px"
            zIndex={2}
          >
            <Stack
              maxWidth="600px"
              spacing={5}
              className={Styles.titleContainer}
            >
              <motion.div
                initial={{ opacity: 0, x: 30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className={Styles.title}
              >
                {heroContent[heroIndex].content}
              </motion.div>
              {/* <Stack justifySelf="start"> */}
              <Link to="/projects" >
                <button className={Styles.btn}>
                  <span style={{ fontSize: '13px', fontWeight: 900, fontFamily: 'Poppins-SemiBold' }}> Discover more </span>

                  <ArrowForwardIos sx={{ fontSize: { xs: "13px" } }} />
                </button>
              </Link >



              {/* </Stack> */}
            </Stack>

            <Stack spacing={3} direction={{ xs: "row", md: "column" }}>
              <div
                style={{
                  backgroundImage: `url(${heroContent[heroIndex].logo})`,
                }}
                className={Styles.companyLogo}
              />
              <div
                style={{
                  backgroundImage: `url(${heroContent[heroIndex].flag})`,
                }}
                className={Styles.companyLogo}
              />
            </Stack>
            {/* </Box> */}
          </Stack>

          <Box display={{ md: "block" }}>
            <Box className={Styles.arrowBtnContainer}>
              <IconButton
                onClick={() => handleClick("dec")}
                className={Styles.arrowBtn}
                sx={{
                  padding: { xs: "12px", md: "16px" },
                  borderRadius: "50%",
                  border: "1px solid white",
                  color: "white",
                  height: "fit-content",
                }}
              >
                <ArrowBackIos sx={{ fontSize: { xs: "18px", md: "30px" } }} />
              </IconButton>
              <IconButton
                onClick={() => handleClick("inc")}
                sx={{
                  padding: { xs: "12px", md: "16px" },
                  borderRadius: "50%",
                  border: "1px solid white",
                  color: "white",
                  height: "fit-content",
                }}
              >
                <ArrowForwardIos
                  sx={{ fontSize: { xs: "18px", md: "30px" } }}
                />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
      <div className={Styles.serviceContainer}>
        <Grid container justifyContent={"center"} spacing={1}>
          {services.map((service) => (
            <Grid item xs={4} md={2} key={service.id}>
              <ServiceCard data={service} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

function ServiceCard({ data }) {
  return (
    <div className={Styles.serviceCard}>
      <img src={data.img} alt={data.name} className={Styles.serviceIcon} />

      <div className={Styles.serviceText}>{data.name}</div>
    </div>
  );
}
