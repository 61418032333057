import lovedale_school from "../../../assets/clients/lovedale-school-logo.png";
import middle_earthhr from "../../../assets/clients/middle-earthhr-logo.png";
import docle_logo from "../../../assets/clients/docle-logo.png";
import Skillathon_logo from "../../../assets/clients/Skillathon-logo.png";
import dr_amal_alias_logo from "../../../assets/clients/dr-amal-alias-logo.png";
import crapbin_logo from "../../../assets/clients/crapbin-logo.png";
import rice_boel_logo from "../../../assets/clients/rice-boel-logo.jpg";
import billinarios_logo from "../../../assets/clients/billinarios-logo1.png";
import taubahorg_log from "../../../assets/clients/taubah.org-log.jpg";
import aramcoLogo from "../../../assets/clients/aramco.svg";
import samiLogo from "../../../assets/clients/samiLogo.png";
import pepsiLogo from "../../../assets/clients/pepsilogo.svg";

export const clientsImages = [
  { img: aramcoLogo },
  { img: samiLogo },
  { img: pepsiLogo },
  { img: lovedale_school },
  { img: middle_earthhr },
  { img: docle_logo },
  { img: Skillathon_logo },
  { img: dr_amal_alias_logo },
  { img: crapbin_logo },
  { img: rice_boel_logo },
  { img: billinarios_logo },
  { img: taubahorg_log },
  { img: pepsiLogo },
  { img: lovedale_school },
  { img: aramcoLogo },

  { img: middle_earthhr },
  { img: Skillathon_logo },
  { img: crapbin_logo },
  { img: samiLogo },

  { img: docle_logo },
  { img: Skillathon_logo },
  { img: taubahorg_log },
  { img: billinarios_logo },
  { img: rice_boel_logo },
];
