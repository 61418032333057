
import app from "../../../App.module.scss";
import Styles from "./TechStack.module.scss";
import home from "../Index.module.scss";
import {languages, frameWorks,platform} from "./data";

import { scrollToSection } from "../navbar/Navbar";
import { Link } from "react-router-dom";
// import AndroidOutlinedIcon from '@mui/icons-material/AndroidOutlined';
export default function TechStack() {


    return (
        // <div style={{ overflow: "hidden" }} className="row px-lg-5 py-5 bg-white ">
        <section className={`row px-lg-5 ps-1  py-md-5 pb-5 ${Styles.container}`}>
            <div

            >
                <div className={`${app.section_sub_box}`}>
                    <div className={`${app.section_sub_bar}`}></div>
                    <div className={`${app.section_sub_title}`}>OUR EXPERTISE</div>
                </div>
                <section className={`${Styles.innerContainer} row`}>
                    <div className="col-md-6 p-lg-5">
                        <div className="p-lg-4">
                            {/* <div className={Styles.heading}>Our Techstack</div> */}
                            <div className={Styles.heading}>Our Mobile App Development Stack</div>
                            <div className={Styles.desc}>
                                Wholphin Tech leverages the latest technologies to design, develop and launch lightweight,
                                fast, and robust mobile applications. Our mobile app development stack includes
                                native iOS and Android and hybrid technologies that allow us to build apps as per the
                                requirements and to ensure maximum efficiency and ideal results.
                            </div>

                            <div style={{ width: "fit-content" }} className="mt-4 fit-content">

                                <Link
                                 onClick={() => { scrollToSection("contact") }}
                                    className={`${home.btn_effect_2} px-4 py-2 mb-2 ${app.cursor} overflow-hidden`}
                                >
                                    Share Your Requirements
                                </Link>

                            </div>
                        </div>
                    </div>

                    <div
                    data-aos="zoom-out"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    className={`col-md-6 ${Styles.techonologis}`}>
                        <div className=" col-12" >
                            <div className={`${Styles.title}`}> Languages </div>
                            <div className={Styles.language_icons}>
                                {
                                    languages && languages?.map((item, index) => (
                                        <div key={index} className={Styles.icon_box}>
                                            <item.icon className={Styles.icon} />
                                            <span>{item.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`mt-4 ${Styles.title}`}> FrameWorks </div>
                            <div className={Styles.language_icons}>
                                {
                                    frameWorks && frameWorks?.map((item, index) => (
                                        <div key={index} className={Styles.icon_box}>
                                            <item.icon className={Styles.icon} />
                                            <span>{item.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="col-12">
                            <div className={`mt-4 ${Styles.title}`}> Platforms </div>
                            <div className={Styles.language_icons}>
                                {
                                    platform && platform?.map((item, index) => (
                                        <div key={index} className={Styles.icon_box}>
                                            <item.icon className={Styles.icon} />
                                            <span>{item.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </section>
    )
}

