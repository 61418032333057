import { Box, Grid, Typography } from "@mui/material"
import Styles from "./ProjectLandingPage.module.scss"
import { CustomeCard, LikesCard } from "../../../components/atoms/CustomeCard/CustomeCard";
import { AnimatePresence, motion } from "framer-motion"

import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, Outlet, Link } from 'react-router-dom';
import { products1 } from "./data";


import 'swiper/css/effect-fade';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// app feature icons
import icon1 from "../../../assets/icons/comment.png"
import icon2 from "../../../assets/icons/male-telemarketer.png"
import icon3 from "../../../assets/icons/browser.png"
import icon4 from "../../../assets/icons/vector.png"
import icon5 from "../../../assets/icons/cell-phone.png"
import icon6 from "../../../assets/icons/eye-scanner 1.png"

// icons 
import likes from "../../../assets/icons/thumb-up-fill.png"
import download from "../../../assets/icons/download-2-fill.png"
import rating from "../../../assets/icons/star-fill.png"
import imgs1 from "../../../assets/images/Gravity-Scene-iPhone-12-Mockup 1.png"
import BackNavbar from "../navbar/BackNavbar";


export default function ProjectLandingPage(props) {
    const { id } = useParams()
    console.log(props, id);
    let projectData = products1.find((item, index) => item.id.toString() === id)
    console.log(projectData);
    return (
        // <AnimatePresence>
        // <motion.div 
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        // onClick={()=>{setSelectedId(null)}}
        // layoutId={selectedId}
        // >
        <section className={`${Styles.container}`}>
            <BackNavbar />
            <Grid className={`${Styles.banner_swipper}`}>
                <div className={`${Styles.banner}`}>
                    <div className={`${Styles.leftside}`}>
                        <div className={`${Styles.rectangular}`}>
                            <div className={`${Styles.textBox}`}>
                                <div>
                                    <h6 className={`mb-lg-2 ${Styles.text_heading}`}>{projectData.title}</h6>
                                    <p className={`${Styles.section_para}`}>{projectData.desc}</p>
                                </div>
                                <div className={`${Styles.app_download}`}>
                                    <h6 className={`${Styles.download_text}`}>Download App Now</h6>
                                    <div>
                                        <a href={projectData.link} target="_blank">
                                            <img className={`${Styles.download_icon} me-3`} src="../images/figma/google-play-icon.png" />
                                        </a>
                                        <img className={`${Styles.download_icon}`} src="../images/figma/apple-store-icon.png" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${Styles.rightside}`}>
                        <img
                            className={`${Styles.banner_img}`}
                            // src="../images/figma/Free Ui View Mobile App Mockup 1.png" 
                            src={projectData && projectData?.mobile}
                        />
                    </div>
                </div>
            </Grid>

            {/* About our app section */}
            <div className={`${Styles.about_app_section}`}>
                <div >
                    <h6 className={`${Styles.section_heading}`}>About our App</h6>
                    <p className={`${Styles.section_para}`}>{projectData.aboutAppDesc}</p>
                </div>
                <div className="row">
                    <div className={`col-12  col-md-5 col-lg-6 m-auto `}>
                        {/* <img src="../images/figma/android-smartphone-free-mockup 1.png" /> */}
                        <div className={` ${Styles.abt_section_img}`}>
                            <img src={projectData?.subImages && projectData?.subImages[0]} />
                        </div>
                    </div>
                    <div className={`col-12  col-md-7 col-lg-6`}>

                        {
                            projectData?.aboutAppSubDesc && projectData?.aboutAppSubDesc.map((item, index) => (
                                <div key={index} className={`${Styles.abt_card}`}>
                                    <div className="me-3">
                                        <img src="../images/figma/checkbox-circle-fill.png" />
                                    </div>
                                    <div className="">
                                        <h6 className={Styles.section_sub_heading}>
                                            {item.title}
                                        </h6>
                                        <p className={Styles.section_para}>{item.description}</p>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>
            </div>

            {/* App features */}
            <div className={`${Styles.feature_swipper} `}>
                <div >
                    <h6 className={`${Styles.section_heading}`}>App features</h6>
                    <p className={`${Styles.section_para}`}>{projectData.featureDesc}</p>
                </div>

                <div className={`${Styles.feature_box}`}>
                    <div className="mb-2">
                        <CustomeCard text_align="center"
                            icon={icon1} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[0]?.title}
                            description={projectData?.featureSubDesc && projectData?.featureSubDesc[0]?.desc}
                        />
                    </div>
                    <div className={`${Styles.img_box}`}>
                        <div className={`${Styles.image_left_side}`}>
                            <CustomeCard text_align="end"
                                icon={icon3} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[2].title}
                                description={projectData?.featureSubDesc && projectData?.featureSubDesc[2].desc}
                            />
                            <CustomeCard text_align="end"
                                icon={icon4} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[3].title}
                                description={projectData?.featureSubDesc && projectData?.featureSubDesc[3].desc}
                            />
                        </div>
                        <div className={`${Styles.img_feature}`}>
                            {/* <img className={`${Styles.image}`} src="../images/figma/app-feature.png" /> */}
                            <img className={`${Styles.image}`}
                                src={projectData.subImages && projectData.subImages[1] ?
                                    projectData.subImages[1]
                                    : projectData.subImages[0]

                                }
                            />
                        </div>
                        <div className={`${Styles.image_right_side}`}>
                            <CustomeCard
                                text_align="left"
                                icon={icon5} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[4].title}
                                description={projectData?.featureSubDesc && projectData?.featureSubDesc[4].desc}
                            />
                            <CustomeCard
                                text_align="left"
                                icon={icon6} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[5].title}
                                description={projectData?.featureSubDesc && projectData?.featureSubDesc[5].desc}
                            />
                        </div>

                    </div>
                    <div className="mt-2">
                        <CustomeCard
                            text_align="center"
                            icon={icon2} heading={projectData?.featureSubDesc && projectData?.featureSubDesc[1].title}
                            description={projectData?.featureSubDesc && projectData?.featureSubDesc[1].desc} />
                    </div>
                </div>
            </div>

            {/* Checkout Our App Interface Look */}
            <div className={`my-5 ${Styles.screenshot_swipper} `}>
                <div >
                    <h6 className={`mb-3 ${Styles.section_heading}`}>Checkout Our App Interface Look</h6>
                    <p className={`${Styles.section_para}`}>{projectData.appLookDesc}</p>
                </div>

                <Swiper
                    effect={'coverflow'}
                    // perSlideOffset={12}
                    coverflowEffect={{
                        rotate: 45,
                        stretch: 0,
                        depth: 30,
                        // perSlideOffset: 12,
                        // slideShadows: true,
                    }}
                    centeredSlides={true}
                    slidesPerView={3}
                    modules={[EffectCoverflow, Pagination, Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    pagination={{ clickable: true }}
                    loop={true}
                    className={Styles.mySwiper}

                    breakpoints={{
                        // 640: {
                        //     slidesPerView: 1,
                        //     spaceBetween: 10,
                        //     slideShadows: false,
                        // },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        }
                    }}

                >
                    {projectData?.subImages && projectData?.subImages.map((item, index) => (
                        <SwiperSlide key={index} className={`${Styles.swiper_slide}`}>
                            <img src={item} className={Styles.image} />
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="row m-auto mt-5">
                    <div className="col-md-6 m-auto">
                        <h6 className={`mb-2 ${Styles.download_text}`}>Download App Now</h6>
                        <p className={` ${Styles.section_para1}`}>{projectData.desc}</p>
                        <div className={`  ${Styles.app_download}`}>
                            <div>
                                <a href={projectData.link} target="_blank">
                                    <img
                                        className={`${Styles.download_icon} me-3`} src="../images/figma/google-play-icon.png" />
                                </a>
                                <img className={`${Styles.download_icon}`} src="../images/figma/apple-store-icon.png" />
                            </div>
                        </div>
                        <div className={` ${Styles.likes_container}`}>
                            <LikesCard icon={download} title="Download" count={projectData.downloads1} />
                            <LikesCard icon={likes} title="Like" count={projectData.likes} />
                            <LikesCard icon={rating} title="5 Star Rating" count={projectData.rating} />
                        </div>
                    </div>

                    <div className={` col-md-6 m-lg-auto ${Styles.screen_image}`}>
                        <img src={imgs1} />
                    </div>
                </div>

            </div>





        </section>
        // </motion.div>
        // </AnimatePresence>
    )
}