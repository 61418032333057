import { blogsData } from "./data"
import Styles from "./ChildBlog.module.scss"
import ShareConatiner from "../../reusable/ShareContainer/ShareConatiner"
import { useParams, Outlet } from 'react-router-dom';
import BackNavbar from "../navbar/BackNavbar";
import logo from "../../../assets/images/company-logo2.gif"


export default function ChildBlog(props) {

    const { id } = useParams();
    const blog = blogsData.find((item) => item.id.toString() === id);
    console.log(id);

    if (!blog) {
        return <div>Blog not found.</div>;
    }

    return (
        <section className={`${Styles.container}`}>
            <BackNavbar />

            <div className={`${Styles.container_swipper}`}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom"
            >
                <div className="row
                 ">
                    <div className={`${Styles.blog_header}`}>
                        <h3 className={`${Styles.heading}`}>
                        {/* <div className={Styles.blog1}>BLOGS</div> */}
                            {blog.heading}
                        </h3>
                    </div>
                    <div className={`${Styles.banner_container}`}>
                        <div className={`${Styles.image}`}>
                            <img src={blog.img} />
                        </div>
                        <div className={`${Styles.right_side}`}>
                            <div>
                                <img src={logo} className={`${Styles.logo}`} />
                            </div>

                            <div className={Styles.content}>
                                <h6 className={Styles.text}>{blog.heading}</h6>
                                <h6 className={Styles.date}>{blog.date}</h6>
                            </div>
                        </div>
                    </div>


                    <section className="row col-12">
                        <div className=" col-lg-10">
                            {blog.para}
                        </div>

                        <div className={`col-2 d-none d-lg-flex  ${Styles.profile}`}>

                            <div className={`${Styles.ceo_details}`}>
                                <img src="https://as1.ftcdn.net/v2/jpg/04/01/50/22/1000_F_401502221_ZpRk4KB75EgR2DzMauXr28sdYYr3gC36.jpg" height={"50px"} />
                                <h5>Ahmeer</h5>
                                <p>Founder and CEO</p>
                            </div>

                            <div className={`${Styles.views}`}>
                                <p><span>340</span> Views</p>
                                <p><span>40</span> Minute Read</p>
                            </div>

                            <ShareConatiner />
                        </div>
                    </section>
                </div>
            </div>

            <Outlet></Outlet>
        </section>
    )
}