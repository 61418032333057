import React, { useEffect, useRef, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import FooterForm from "../footerForm/FooterForm";
import Nav from "./navbar/Navbar";
import SecondSection from "./mobileDevelopment/SecondSection";
import FirstBanner from "./homeBanner/FirstBanner";
import ProjectSection from "./projects/ProjectSection";
import Clients from "./clients/Clients";
import TechStack from "./techStack/TechStack";
import FivthSection from "./whyChoose/FivthSection";
import EightSection from "./howWeWork/EightSection";
import SeventhSection from "./caseStudies/SeventhSection";
import AOS from "aos";
import "aos/dist/aos.css";
import BlogSection from "./blogs/BlogSection";
import MyNavbar from "./navbar/Navbar";
import ScreenShotsCarousel from "./screenShots/ScreenShotsCarousel";
import AllProject from "./projects/AllProject";


import { Box } from "@mui/material";
import HeroSectionPage from "../hero/HeroSectionPage";

export default function LandingPage() {
  return (
    <div>
      <div style={{ backgroundColor: "var(--bg_primary1)", overflowX:"hidden" }}>
        {/* <Nav /> */}
        <MyNavbar />
        {/* First section */}
        <div id="home">
          {/* <FirstBanner /> */}
          <Box >
            <HeroSectionPage />
          </Box>
        </div>
        {/* Second section */}
        {/* <div id="services">
          <SecondSection />
        </div> */}
        {/* Third section */}
        <div id="projects" style={{ overflow: "hidden" }}>
          <ProjectSection />
        </div>
        {/* FOURTH SECTION */}
        <div id="clients">
          <Clients />
        </div>
        {/* Fivth section  */}

        {/* SIXTH SECTION */}

        <div id="techStack">
          <TechStack />
        </div>
        {/* Seventh SECTION */}
        {/* <SeventhSection /> */}
        {/* Eight section */}
        <ScreenShotsCarousel />
        <div id="process">
          <EightSection />
        </div>

        <div id="blog">
          <BlogSection />
        </div>
        <FivthSection />
        <FooterForm />
      </div>
    </div>
  );
}
