import React, { useEffect, useRef, useState } from "react";
import "animate.css";
import { FaEnvelopeOpen, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
import index from "./footerForm.module.scss";
import style from "../home/Index.module.scss";
import app from "../../App.module.scss";
import InputField from "../../components/atoms/InputField/Input1";

export default function FooterForm() {
  const [scrolY, setScrolY] = useState(false);
  const [scrolY2, setScrolY2] = useState(false);
  const [scrolY3, setScrolY3] = useState(false);
  const [scrolY4, setScrolY4] = useState(false);

  const ref = useRef();
  const reff = useRef();
  const refff = useRef();
  const reffff = useRef();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      scrollT();
      scrollT2();
      scrollT3();
      scrollT4();
    });
  }, []);

  const scrollT = () => {
    if (window.pageYOffset + window.innerHeight >= ref.current.offsetTop) {
      setScrolY(true);
    } else {
      setScrolY(false);
    }
  };
  const scrollT2 = () => {
    if (window.pageYOffset + window.innerHeight >= reff.current.offsetTop) {
      setScrolY2(true);
    } else {
      setScrolY2(false);
    }
  };
  const scrollT3 = () => {
    if (window.pageYOffset + window.innerHeight >= refff.current.offsetTop) {
      setScrolY3(true);
    } else {
      setScrolY3(false);
    }
  };
  const scrollT4 = () => {
    if (window.pageYOffset + window.innerHeight >= reffff.current.offsetTop) {
      setScrolY4(true);
    } else {
      setScrolY4(false);
    }
  };

  return (
    <div className={`row ${index.container}`} >
      {/* <div className=" container" style={{ overflow: "hidden" }}> */}
        <div className={app.section_sub_box}>
          <div className={` ${app.section_sub_bar}`} />
          <div className={` ${app.section_sub_title}`}>
            CONTACT US
          </div>
        </div>
        <div className={`col-md-8 my-2 mb-md-5 ${app.section_title}`}>
          How Can We Help You Succeed
        </div>

        {/* <div id="footer" className={`row px-xl-5 px-3 ${app.justify_c}`}> */}
        <div id="footer" className={`row ${app.justify_c} px-lg-5`}>
          <div
            ref={ref}
            className={`${scrolY && index.show} ${index._form} col-12  px-lg-3 col-md-6 col-lg-6`}
          >
            <form
              action=""
              className={`${index.footer_input} ${index.footer_border}  px-5 py-4`}
            >
              <label className={`${index.form_title} mb-lg-2`}>Have a requirement? Let's discuss.</label>

              <InputField label="Name" />
              <InputField label="Email" />
              <InputField label="Phone" />
              <InputField label="select country" />
              <InputField label="company" />
              <InputField label="your requirements" />
            
              <div
                style={{ borderRadius: "100px", width: "fit-content" }}
                className="mt-4"
              >
                <div
                  className={`${style.btn_effect} px-4 py-2 ${app.cursor} `}
                >
                  Send Message
                </div>
              </div>
            </form>
          </div>
          <div id="contact" className="col-12 col-md-6 px-lg-3 col-lg-5">
            <div
              ref={reff}
              className={`${scrolY2 && index.show} ${index._form2} ${index.footer_border
                }
              } mt-4 mt-md-0 px-4 py-3`}
            >
              <div className={`row ${index.justify_b}`}>
                <div className={`${index.footer_icon} col`}>
                  <FaEnvelopeOpen className={`${index.div}`} />
                </div>
                <div className="col-10">
                  <div className={`${index.footer_title}`}>Write to us</div>
                  <div className={`${app.section_desc}`}>
                    admin@wholphintech.com
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={refff}
              className={`${scrolY3 && index.show} ${index._form3} ${index.footer_border
                } my-4 px-4 py-2`}
            >
              <div className={`row ${index.justify_b} my-4`}>
                <div className={`${index.footer_icon} col`}>
                  <FaPhoneAlt className={`${index.div}`} />
                </div>
                <div className="col-10">
                  <div className={`${index.footer_title}`}>
                    Let's talk business
                  </div>
                  <div className={`${app.section_desc} text-muted`}>
                    India: +91 7993030801
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={reffff}
              className={`${scrolY4 && index.show} ${index._form4} ${index.footer_border
                } px-4 py-4`}
            >
              <div className={`row ${index.justify_b}`}>
                <div className={`${index.footer_icon} col`}>
                  <FaLocationArrow className={`${index.div}`} />
                </div>
                <div className="col-10">

                  <div className={`${index.footer_title}`}>India</div>
                  <div className={`${app.section_desc} text-muted`}>
                    Wholphintech  ,Ameerpet, Hyderabad. Telangana-500073.
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
}
